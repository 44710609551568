import React, { useContext, useEffect, useRef, useState } from 'react';
import { ConfiguratorContext } from '../contexts/ConfiguratorContext';
import { CSSTransition, SwitchTransition, TransitionGroup } from "react-transition-group";
import './Visualization.scss';
import { TypeOfExpression } from 'typescript';
import Hotspots from './Hotspots';

function Visualization() {
    const { currentConfiguration } = useContext(ConfiguratorContext);
    const [state, setState] = useState(true);

  //  const enterRef = useRef<HTMLImageElement>(null);
  //  const exitRef = useRef<HTMLImageElement>(null);

    // const imgRef = state ? enterRef : exitRef;

    const imgRef = useRef<HTMLImageElement>(null);   
    let imageUrl = `rooms/${currentConfiguration.room.id}/${currentConfiguration.room.id}_${currentConfiguration.horizontalPlacement.id}_${currentConfiguration.verticalPlacement.id}_${currentConfiguration.design.id}.jpg`

    useEffect(() => {
        
        const refImg = imgRef.current as HTMLImageElement | null;

        function resize() {        
            if (refImg) {
                refImg.style.objectFit = "contain";
            }
        }

        window.addEventListener("resize", resize);
        resize();
        return () => window.removeEventListener("resize", resize);

    });

    return (
        <>
        <div className='visualization'>
            <TransitionGroup>
                <CSSTransition               
                    key={imageUrl}
                    timeout={500}
                    classNames='fade-withdelay'>
                    <img alt='' ref={imgRef} src={imageUrl} onLoad={() => setState(state => !state)} className="rendering" />
                </CSSTransition>
            </TransitionGroup>            
        </div>
        <Hotspots configuration={currentConfiguration} />
        </>
    );
}

export default Visualization;
