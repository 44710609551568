import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from "i18next";
import common_sv from "./translations/sv/common.json";
import common_en from "./translations/en/common.json";
import common_da from "./translations/da/common.json";

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: "en",                              // language to use
  resources: {
    en: {
      common: common_en               // 'common' is our custom namespace
    },
    da: {
      common: common_da
    },
    sv: {
      common: common_sv
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(

  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Router>
        <App />
      </Router>
    </I18nextProvider>
  </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
