import { FC } from 'react';
import { isBrowser, isMobile, isMobileOnly } from 'react-device-detect';
import {useTranslation} from "react-i18next";
import "./DeviceOverlay.scss";

export const DeviceOverlay: FC = () => {

    const {t, i18n} = useTranslation('common');

    return <>
    {isMobileOnly ? <div className='device-overlay-wrapper'>
        <div className='device-overlay'>
            {t("device.onlydesktop")}
        </div>
    </div> : null
    }
    </>
}