import { FC, useEffect, useRef, useState } from "react"
import { IConfiguration } from "../models/Configuration";
import "./Hotspots.scss";

interface HotspotsProps {
    configuration: IConfiguration   
}

export const Hotspots: FC<HotspotsProps> = (props: HotspotsProps) => {

    const [showOverlay, setShowOverlay] = useState(false);   

    let imgWidth: number;
    let imgHeight: number;
    let windowWidth: number;
    let windowHeight: number;
    let topOffset: number = 0;
    let leftOffset: number = 0;
    
    let style = {};
    let overlayClass = "hotspot-overlay";

    let hotspot = useRef<HTMLAnchorElement>(null);

    let imageUrl = `closeups/${props.configuration.room.id}_Beam_Closeup_${props.configuration.design.id}.jpg`;

    const onClick = () => {       
       setShowOverlay(true);
    }

    const onClose = () => {
        setShowOverlay(false);
    }

    useEffect(() => {

        function resize() {

            imgWidth = 2500;
            imgHeight = 1750;
            windowWidth = window.innerWidth;
            windowHeight = window.innerHeight;

            let topOffsetPct = props.configuration.room.hotspot.top / 100;
            let leftOffsetPct = props.configuration.room.hotspot.left / 100;
      
            var imgWidthAspect = windowWidth / imgWidth;
            var imgHeightAspect = windowHeight / imgHeight;

            topOffset =  (windowHeight - (imgWidthAspect*imgHeight))/2;
            topOffset = (topOffset > 0) ? topOffset : 0; 
            if (imgHeightAspect < imgWidthAspect) {
                imgWidthAspect = imgHeightAspect;
             }
            topOffset +=  (imgWidthAspect*(imgHeight * topOffsetPct));    

            leftOffset =  (windowWidth - (imgHeightAspect*imgWidth))/2;
            leftOffset = (leftOffset > 0) ? leftOffset : 0;  
            if (imgWidthAspect < imgHeightAspect) {
                imgHeightAspect = imgWidthAspect;
             }
            leftOffset += (imgHeightAspect*(imgWidth * leftOffsetPct));        
                    
            style = { position: "absolute", top: topOffset, left: leftOffset };

            if (hotspot.current != undefined) {
                hotspot.current.style.position = "absolute";
                hotspot.current.style.top = topOffset+"px";
                hotspot.current.style.left = leftOffset+"px";
            }

        }

        window.addEventListener("resize", resize);
        resize();
        return () => window.removeEventListener("resize", resize);

    })

    return <div className="hotspots">
        <a ref={hotspot} onClick={() => onClick()}><img src="search.png"/></a>       
        <div className={showOverlay ? "hotspot-overlay show" : "hotspot-overlay" }>           
            <div className="hotspot-overlay-bg"></div>
            <div className="hotspot-overlay-close" onClick={() => onClose() }><img src="close.png"/></div>
            <img src={imageUrl}/>
        </div>     
    </div>
}

export default Hotspots;