import React, { useContext } from 'react';
import { ConfiguratorContext } from '../contexts/ConfiguratorContext';
import './Configurator.scss';
import { ReactComponent as Logo } from '../assets/logo.svg';
import Visualization from './Visualization';
import Menu from './Menu';
import { DeviceOverlay } from './DeviceOverlay';

function Configurator() {
    const { currentConfiguration } = useContext(ConfiguratorContext);

    if (currentConfiguration === undefined) {
        return null;
    }

    return (
        <>
            <Visualization /> 
            <Menu />
            <DeviceOverlay/>
        </>
    );
}

export default Configurator;
