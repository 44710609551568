import React, { createContext, useState, FC, PropsWithChildren } from "react";
import { ICustomizerData } from "../models/CustomizerData";

export interface DataContextState {
    loading: boolean;
    data: ICustomizerData;
};

const contextDefaultValues: DataContextState = {
    loading: false,
    data: {
        rooms: [
            {
                id: "startup",
                name: "Startup",
                hotspot: {
                    top: 25,
                    left: 72
                }
            },
            {
                id: "newoffice",
                name: "New office",
                hotspot: {
                    top: 30,
                    left: 30
                }
            },
            {
                id: "hotelroom",
                name: "Hotel room",
                hotspot: {
                    top: 20,
                    left: 50
                }
            },
            {
                id: "flexibleoffice",
                name: "Flexible office",
                hotspot: {
                    top: 35,
                    left: 75
                }
            },
        ],
        horizontalPlacements: [
            {
                id: "wall2wall",
                name: "FullWall"                
            },{
                id: "wall2beam",
                name: "Wall2Beam"               
            },{
                id: "beam",
                name: "Beam"               
            },{
                id: "beam2wall",
                name: "Beam2Wall"                
            }
        ],
        verticalPlacements: [
            {
                id: "SealedToCeiling",
                name: "Sealed to ceiling"               
            },
            {
                id: "DistanceToCeiling",
                name: "Distance to ceiling"                
            }
        ],
        designs: [
            {
                id: "Alea",
                name: "Alea"
            },
            {
                id: "Badge",
                name: "Badge"
            },
            {
                id: "Clyp",
                name: "Clyp"
            },
            {
                id: "Cubo",
                name: "Cubo"
            },
            {
                id: "Gap",
                name: "Gap"
            },           
            {
                id: "Trac",
                name: "Trac"
            },
            {
                id: "Zune",
                name: "Zune"
            }
        ]
    },
};

export const DataContext = createContext<DataContextState>(
    contextDefaultValues
);

export const DataProvider: FC<PropsWithChildren> = ({ children }) => {
    const [loading] = useState(contextDefaultValues.loading);
    const [data] = useState(contextDefaultValues.data);

    return (
        <DataContext.Provider
            value={{
                loading,
                data
            }}
        >
            {children}
        </DataContext.Provider>
    );
};