import React, { useContext } from 'react';
import './App.scss';
import { DataContext } from './contexts/DataContext';
import { ConfiguratorContext, ConfiguratorProvider } from './contexts/ConfiguratorContext';
import Configurator from './components/Configurator';
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import ReactGA from 'react-ga4';

const TRACKING_ID = "UA-XXXXX-X";
//ReactGA.initialize(TRACKING_ID);

function App() {
    const { data } = useContext(DataContext);
    const [t, i18n] = useTranslation('common');
    const [searchParams, setSearchParams] = useSearchParams();   
    const lang = searchParams.get("lang") ?? "en";

    if (i18n.language !== lang && 
        i18n.hasResourceBundle(lang,"common")) {        
        i18n.changeLanguage(lang);       
    }
    document.documentElement.lang = lang;

    return (
        <ConfiguratorProvider data={data}>
            <ConfiguratorContext.Consumer>
                {() =>
                    <div className="configurator">
                        <Configurator />
                    </div>}
            </ConfiguratorContext.Consumer>
        </ConfiguratorProvider>
    );
}

export default App;
