import React from 'react';
import { ReactComponent as Arrows } from '../assets/arrows.svg';
import { ReactComponent as Logo } from '../assets/logo.svg';
import './Confirmation.scss';
import Visualization from './Visualization';
import { CSSTransition } from 'react-transition-group';

interface Props {
    visible:boolean;
    onClose:() => void;
}
 

function Confirmation(props:Props) {
    

    return (
        <CSSTransition in={props.visible} timeout={500} classNames="fade" unmountOnExit={true}>
        <div className='confirmation'>
                <header>
                    <div className='title'>
                        <Logo className="logo" />
                        <button onClick={() => props.onClose()}>
                            <Arrows /> New configuration
                        </button>
                    </div>

                    <h1>Thank you for using our configurator!</h1>
                    <h2>Email has been sent with your PDF</h2>
                </header>
                
                <div className='content'>
                    <Visualization />
                    <div className='info'>
                        <h2>Additional features</h2>
                        <h3>All covers can be delivered with additional features:</h3>

                        <ul>
                            <li>lighting</li>
                            <li>speakers</li>
                            <li>sprinkler</li>
                            <li>smoke detector</li>
                            <li>up light</li>
                            <li>RAL colour on demand</li>
                        </ul>

                        <h3>Other wishes?</h3>
                        <p>Talk to your <a href="https://www.lindab.dk/">Lindab partner</a></p>

                        <button><Arrows /> Contact</button>
                    </div>
                </div>
        </div>
        </CSSTransition>
    );
}

export default Confirmation;
