import React, { createContext, useState, FC, useEffect, PropsWithChildren } from "react";
import { ICustomizerData, IDesign, IPlacement, IRoom } from "../models/CustomizerData";
import { IConfiguration } from "../models/Configuration";

export interface ConfiguratorContextState {
    currentConfiguration: IConfiguration;
    setSelectedRoom: (room: IRoom) => void;
    setSelectedHorizontalPlacement: (placement: IPlacement) => void;
    setSelectedVerticalPlacement: (placement: IPlacement) => void;
    setSelectedDesign: (design: IDesign) => void;
 };

const contextDefaultValues: ConfiguratorContextState = {
    currentConfiguration: {
        design: {
            id: "",
            name: "",
        },
        horizontalPlacement: {
            id: "",
            name: ""           
        },
        room: {
            id: "",
            name: "",
            hotspot: {
                top: 0,
                left: 0
            }
        },
        verticalPlacement: {
            id: "",
            name: ""           
        },
    },    
    setSelectedRoom: () => {},
    setSelectedHorizontalPlacement: () => {},
    setSelectedVerticalPlacement: () => {},
    setSelectedDesign: () => {},
};

export const ConfiguratorContext = createContext<ConfiguratorContextState>(
    contextDefaultValues
);

export const ConfiguratorProvider: FC<PropsWithChildren<{ data: ICustomizerData }>> = ({ data, children  }) => {
    const [currentConfiguration, setCurrentConfiguration] = useState(contextDefaultValues.currentConfiguration);

    useEffect(() => {
        if(data){
            setCurrentConfiguration({
                design: data.designs[0],
                horizontalPlacement: data.horizontalPlacements[1],
                room: data.rooms[0],
                verticalPlacement: data.verticalPlacements[0],
            });

        }
    }, [data]);

    const setSelectedRoom = (room:IRoom) => {
        setCurrentConfiguration({...currentConfiguration, room: room});
    }

    const setSelectedHorizontalPlacement = (placement:IPlacement) => {
        setCurrentConfiguration({...currentConfiguration, horizontalPlacement: placement});
    }

    const setSelectedVerticalPlacement = (placement:IPlacement) => {
        setCurrentConfiguration({...currentConfiguration, verticalPlacement: placement});
    }

    const setSelectedDesign = (design:IDesign) => {
        setCurrentConfiguration({...currentConfiguration, design: design});
    }

    return (
        <ConfiguratorContext.Provider
            value={{
                currentConfiguration,
                setSelectedRoom,
                setSelectedHorizontalPlacement,
                setSelectedVerticalPlacement,
                setSelectedDesign,
            }}
        >
            {children}
        </ConfiguratorContext.Provider>
    );
};