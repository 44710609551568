import React, { useContext, useState, useEffect } from 'react';
import { ConfiguratorContext } from '../contexts/ConfiguratorContext';
import { DataContext } from '../contexts/DataContext';
import { ReactComponent as Arrows } from '../assets/arrows.svg';
import './Menu.scss';
import DownloadDialog from './DownloadDialog';
import Confirmation from './Confirmation';
import { IPlacement, ITranslationRoom, IRoom } from '../models/CustomizerData';
import {useTranslation} from "react-i18next";

function Menu() {
    const { data } = useContext(DataContext);
    const { currentConfiguration, setSelectedRoom, setSelectedHorizontalPlacement, setSelectedVerticalPlacement, setSelectedDesign } = useContext(ConfiguratorContext);
    const [currentTab, setCurrentTab] = useState(1);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [confirmationVisible, setConfirmationVisible] = useState(false);

    const {t, i18n} = useTranslation('common');

    const roomsTranslation = i18n.getResourceBundle(i18n.language, "common").rooms as Array<ITranslationRoom>;

    /*const isDisabled = (placement: IPlacement) => {
        return (!placement.validRooms.includes(currentConfiguration.room.id));
    }*/

    /*useEffect(() => {

        if (currentConfiguration.room.id) {
            let selectedRoom = currentConfiguration.room;
            if (!currentConfiguration.horizontalPlacement.validRooms.includes(selectedRoom.id)) {
                setSelectedHorizontalPlacement(data.horizontalPlacements.filter(placement => { return placement.validRooms.includes(selectedRoom.id)})[0]);
            }

            if (!currentConfiguration.verticalPlacement.validRooms.includes(selectedRoom.id)) {
                setSelectedVerticalPlacement(data.verticalPlacements.filter(placement => { return placement.validRooms.includes(selectedRoom.id)})[0]);
            }
        }

    });*/

    return (
        <>
            <div className='menu'>
                <header>
                    <h1>Plafond XD</h1>
                    <button className={'tab-btn' + ((currentTab === 1) ? ' active' : '')} onClick={() => setCurrentTab(1)}>{t("tabs.room")}</button>
                    <button className={'tab-btn' + ((currentTab === 2) ? ' active' : '')} onClick={() => setCurrentTab(2)}>{t("tabs.design")}</button>
                    <button className={'tab-btn' + ((currentTab === 3) ? ' active' : '')} onClick={() => setCurrentTab(3)}>{t("tabs.placement")}</button>
                    <button className='download' onClick={() => setDialogVisible(true)}><Arrows />{t("tabs.export")}</button>
                </header>
                
                <div className='tabs'>
                    <div className={'tab-item' + ((currentTab === 1) ? ' active' : '')}>
                        <div className='tab-item-images'>                            
                            {roomsTranslation.map(room => <div key={room.id} className={'item-img' + (room.id === currentConfiguration.room.id ? ' selected' : '')} onClick={() => setSelectedRoom(data.rooms.find(x => {return x.id === room.id}) as IRoom)}>
                                    <img src={`rooms/${room.id}/${room.id}_thumb.jpg`} alt={room.name} />
                                    <p>{room.name}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={'tab-item' + ((currentTab === 2) ? ' active' : '')}>
                        <div className='tab-item-images'>
                            {data.designs.map(design => <div key={design.id} className={'item-img' + (design.id === currentConfiguration.design.id ? ' selected' : '')} onClick={() => setSelectedDesign(design)}>
                                    <img src={`designs/${design.id}.jpg`} alt={design.name} />
                                    <p>{design.name}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={'tab-item' + ((currentTab === 3) ? ' active' : '')}>
                        <div className='tab-item-buttons'>
                            <div className='placement-horizontal'>
                                <h2>{t("placements.horizontal.name")}</h2>
                                <div className='tab-item-buttons-container'>
                                    {data.horizontalPlacements.map(placement => <button key={placement.id} className={'item-button' + (placement.id === currentConfiguration.horizontalPlacement.id ? ' selected' : '')} onClick={() => setSelectedHorizontalPlacement(placement)}>
                                            {t("placements.horizontal."+placement.id.toLowerCase())}                                           
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className='placement-vertical'>
                                <h2>{t("placements.vertical.name")}</h2>
                                <div className='tab-item-buttons-container'>
                                    {data.verticalPlacements.map(placement => <button key={placement.id} className={'item-button' + (placement.id === currentConfiguration.verticalPlacement.id ? ' selected' : '')} onClick={() => setSelectedVerticalPlacement(placement)}>
                                            {t("placements.vertical."+placement.id.toLowerCase())}           
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DownloadDialog visible={dialogVisible} onClose={() => setDialogVisible(false)} onDownloaded={() => setDialogVisible(false)} onSent={() => {setConfirmationVisible(true); setDialogVisible(false)}} />
            <Confirmation visible={confirmationVisible} onClose={() => setConfirmationVisible(false)} />
        </>
    );
}

export default Menu;
