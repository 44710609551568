import React, { useContext, useRef, useState } from 'react';
import { ReactComponent as Arrows } from '../assets/arrows.svg';
import { ReactComponent as Download } from '../assets/download.svg';
import { ReactComponent as Close } from '../assets/close.svg';
import './DownloadDialog.scss';
import { CSSTransition } from 'react-transition-group';
import FileSaver from 'file-saver';
import { ConfiguratorContext } from '../contexts/ConfiguratorContext';
import {useTranslation} from "react-i18next";
import { useGAEventTracking } from "../helpers/TrackingHelper";
import HubspotForm from 'react-hubspot-form';

interface Props {
    visible:boolean;
    onClose:() => void;
    onDownloaded:() => void;
    onSent:() => void;
}
 

function DownloadDialog(props:Props) {
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const form = useRef<HTMLFormElement>(null);
    const { currentConfiguration } = useContext(ConfiguratorContext);

    const {t, i18n} = useTranslation('common');

    //const gaEventTracking = useGAEventTracking("Contact");

    const download = () => {
     //   if(form.current?.checkValidity()){

            //gaEventTracking("Download","PDF");
            setLoading(true);

            fetch(`/api/contact/download?room=${currentConfiguration.room.id}&design=${currentConfiguration.design.id}&horizontalPlacement=${currentConfiguration.horizontalPlacement.id}&verticalPlacement=${currentConfiguration.verticalPlacement.id}&lang=${i18n.language}`)
                .then(res => res.blob())
                .then(blob => {
                    FileSaver.saveAs(blob, 'Lindab_Plafond_XD_Tool_Solution.pdf')
                    setLoading(false);
                    props.onDownloaded();
                });
      //  }

      //  form.current?.reportValidity();
    }

    const hasHubspotForm = () => {
        return t("export.portalId") !== "" && t("export.formId") !== ""
    }

    const hasIframe = () => {
        return t("export.iframe") !== ""
    }

   /* const send = () => {
        if(form.current?.checkValidity()){
            setLoading(true);

            setTimeout(() => {
                setLoading(false);
                props.onSent();
            }, 1000);
        }

        form.current?.reportValidity();
    }*/

    return (
        <CSSTransition in={props.visible} timeout={500} classNames="fade" unmountOnExit={true}>
            <div className='dialog-container'>
                <div className={(hasHubspotForm() || hasIframe()) ? 'dialog' : 'dialog autoheight'}>
                    <header>
                        <h1>{t("export.header")}</h1>
                        <button onClick={() => props.onClose()}>
                            <Close />
                        </button>
                    </header>

                    {hasHubspotForm() ? 
                        <div className="external-form">
                            <HubspotForm portalId={t("export.portalId")} formId={t("export.formId")} loading={<div>Loading...</div>} />                           
                        </div>
                        : null
                    }

                    {hasIframe() ?                        
                        <div className="external-iframe" dangerouslySetInnerHTML={{ __html: t("export.iframe") }}>                          
                        </div>
                        : null
                    }

                    {/* <form ref={form}>
                        <div className='content row'>
                            <div className='col'>
                                <input type='text' placeholder={t("export.name") +"*"} name='name' required value={name} onChange={(e) => setName(e.currentTarget.value)} />
                                <input type='text' placeholder={t("export.company") +"*"} name='company' required value={company} onChange={(e) => setCompany(e.currentTarget.value)} />
                                <input type='tel' placeholder={t("export.phone") +"*"} name='phone' required value={phone} onChange={(e) => setPhone(e.currentTarget.value)} />
                                <input type='email' placeholder={t("export.email") +"*"} name='email' required value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
                            </div>
                            <div className='col'>
                                <textarea onChange={(e) => setMessage(e.currentTarget.value)} placeholder={t("export.message")+""} value={message}></textarea>
                            </div>
                        </div>
                        
                        <div className='terms row'>
                            <div className='col'>
                                <label>
                                    <input type='checkbox' required />
                                    <span className='checkmark'></span>
                                    {t("export.accept")}                                   
                                </label>
                            </div>
                            <div className='col'>

                            </div>
                        </div>
                    </form> */}

                    <div className='footer row'>
                        <div className='col'>
                            <button className='btn-download' disabled={loading} onClick={() => download()}><Download />{t("export.download")}</button>
                        </div>
                        {/* <div className='col'>
                            <button className='btn-send' disabled={loading} onClick={() => send()}><Arrows />{t("export.send")}</button>
                        </div> */}
                    </div>
                    
                </div>
            </div>
        </CSSTransition>
    );
}

export default DownloadDialog;
